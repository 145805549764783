import React from "react";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";
import {
  ActionButton,
  AddressDropdown,
  AgencyDropdown,
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BranchDropDown,
  Col,
  ConfirmationAlert,
  FormInput,
  FormInputType,
  IconButton,
  InvoiceTypeDropDown,
  LoadingComponent,
  PersonDropDown,
  PhoneInput,
  PreviewAltCard,
  RadioButtonOptions,
  Row,
  TrustDropDown,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { useHistory, useLocation } from "react-router";
import { addHospitalAPI, getHospitalDetailAPI } from "./HospitalTableData";
import { AppText, RadioOptionTypes, showErrorToast, showSuccessToast } from "../../../../utils";
import { convertToUpperCase } from "../../../../utils/Functions";
import ClientType from "../../../../components/dropdowns/ClientType";
import { useRef } from "react";
import { handleScriptLoad, loadScript } from "../../../../services/google/autocomplete";
import moment from "moment";

const AddHospital = () => {
  const location = useLocation();
  const selectedHospitalId = location.state?.data;
  const history = useHistory();
  const autoCompleteRef = useRef(null);
  const [hospitalData, setHospitalData] = useState({ isMileAllow: true, isActive: true, isPrimaryGroup: false });
  const [personFields, setPersonFields] = useState([{ personName: "", personEmail: "", phone: {}, personType: "" }]);
  const [loading, setLoading] = useState(false);
  const [initScreen, setInitScreen] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [pincode, setPincode] = useState();
  console.log("personFields", personFields);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(onUpdateAddress, autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    if (selectedHospitalId) {
      loadHospitalDetailEdit(selectedHospitalId);
    }
  }, [selectedHospitalId]);

  useEffect(() => {
    const hasEmptyFields =
      personFields.some(
        (person) =>
          person.personName === "" ||
          person.personName === null ||
          person.personName === undefined ||
          person.personEmail === "" ||
          person.personEmail === null ||
          person.personEmail === undefined ||
          person.phone?.phoneNumber === null ||
          person.phone?.phoneNumber === undefined ||
          person.phone?.phoneNumber === "" ||
          !person.phone
      ) ||
      hospitalData.name === "" ||
      hospitalData.abbrCode === "" ||
      hospitalData.address === "" ||
      hospitalData.homeManagers === null ||
      hospitalData.branch === null ||
      hospitalData.branch === undefined ||
      hospitalData.agency === null ||
      hospitalData.agency?.length === 0 || // Check if agency is not chosen
      hospitalData.city === "" ||
      // hospitalData.postalCode === "" ||
      // hospitalData.telephone === "" ||
      hospitalData?.phoneNumber?.phoneNumber === "" ||
      hospitalData?.phoneNumber?.dialCode === "" ||
      hospitalData.invoiceTypes === "";

    const hasErrors =
      hospitalData.isNameError ||
      hospitalData.isEmailError ||
      hospitalData.isPhoneError ||
      hospitalData.isPostalCodeError ||
      personFields.some((person) => person.isEmailError || person.isNameError || person.isPhoneError);

    setIsDisable(hasEmptyFields || hasErrors);
  }, [hospitalData]);

  const addPersonFields = () => {
    setPersonFields([...personFields, { personName: "", personEmail: "", phone: {}, personType: "" }]);
  };

  useEffect(() => {
    setHospitalData((prevData) => ({
      ...prevData,
      personFields: personFields,
    }));
  }, [personFields]);

  const handleSearch = () => {
    setPincode(hospitalData?.postalCode);
  };

  const handleRemovePersonField = (index) => {
    ConfirmationAlert("Remove Person?", "Are you sure, you want to remove this person?", () => {
      setPersonFields((prevState) => {
        const updatedFields = [...prevState];
        updatedFields.splice(index, 1);
        return updatedFields;
      });
    });
  };

  function loadHospitalDetailEdit(selectedHospitalId) {
    setInitScreen(true);
    getHospitalDetailAPI({ clientId: selectedHospitalId }, (data, error) => {
      if (error === null) {
        setHospitalInfo(data);
      }
      setInitScreen(false);
    });
  }

  function setHospitalInfo(selected) {
    setHospitalData((prevState) => ({
      ...prevState,
      id: selected?.id,
      name: selected?.name,
      abbrCode: selected?.abbrCode,
      address: selected?.address,
      homeManagersId: selected?.homeManagers?.id,
      coordinates: {
        latitude: selected?.latitude,
        longitude: selected?.longitude,
      },
      branch: selected?.branch,
      city: selected?.city,
      postalCode: selected?.postalCode,
      comCareNo: selected?.comCareNo,
      referenceNo: selected?.referenceNo,
      // telephone: selected?.telephone,
      phoneNumber: selected?.phoneNumber,
      dialCode: selected?.phoneNumber,
      invoiceTypes: selected?.invoiceTypes,
      specialityIds: selected?.specialityIds,
      agency: selected?.agency,
      trust: selected?.trust,
      isActive: selected?.isActive ?? true,
      isMileAllow: selected?.isMileAllow ?? true,
      isPrimaryGroup: selected?.isPrimaryGroup ?? false,
      lastModifiedBy: selected?.lastModifiedBy,
      lastModifiedTime: selected?.lastModifiedTime,

      isHomeCare:
        selected?.isHomeCare === "HOMECARE"
          ? { label: "Care Home", value: selected?.isHomeCare }
          : { label: "Hospital", value: selected?.isHomeCare },
    }));
    setPersonFields(
      selected?.personFields?.map((person) => ({
        personId: person?.personId,
        personName: person?.personName,
        personEmail: person?.personEmail,
        phone: person?.phoneNumber ? { phoneNumber: person?.phoneNumber, dialCode: person?.countryCode ?? "44" } : {},
        personType: person?.personType,
      })) || []
    );
  }
  // ...

  const onUpdateAddress = (data) => {
    setHospitalData({
      ...hospitalData,
      latitude: data?.lat,
      longitude: data?.lng,
      address: data?.fullAddress,
      city: data?.city,
      state: data?.state,
      country: data?.country,
      postalCode: data?.zipCode,
    });
  };

  const onFormSubmit = () => {
    ConfirmationAlert("Are you sure!", "Do you want to save this client?", () => {
      if (isDisable) return;
      setLoading(true);
      addHospitalAPI(hospitalData, (response, error) => {
        if (!error) {
          let text =
            "Client" +
            " " +
            (hospitalData?.name || "") +
            (selectedHospitalId ? AppText.EditedSuccessfully : AppText.AddedSuccessfully);

          showSuccessToast(text);
          history.push(`${process.env.PUBLIC_URL}/hospitals`);
        } else {
          showErrorToast(error.message);
        }
        setLoading(false);
      });
    });
  };

  const { handleSubmit } = useForm();
  return (
    <React.Fragment>
      <Head title="Add-hospital" />
      <Content>
        <Block className={"justify-center"}>
          <BlockHead size="lg" wide="md">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {selectedHospitalId ? `Edit Client - ${hospitalData?.name}` : `Add Client`}
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <BackButton />
              </BlockHeadContent>
            </BlockBetween>
            <PreviewAltCard className="mt-4">
              <Form className=" form-validation" onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                  <p className="fs-5">Basic</p>
                  <Row className="border rounded-2 mx-auto py-1">
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <FormInput
                            label={"Client name"}
                            required={true}
                            type={FormInputType.default}
                            value={hospitalData?.name ?? ""}
                            placeholder={"Client name"}
                            onError={(isError) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                isNameError: isError,
                              }));
                            }}
                            onTextChange={(name) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                name: name,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <FormInput
                            label={"Client Code"}
                            required={true}
                            type={FormInputType.default}
                            value={hospitalData?.abbrCode ?? ""}
                            placeholder={"Client Code"}
                            onError={(isError) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                isNameError: isError,
                              }));
                            }}
                            onTextChange={(abbrCode) => {
                              const uppercaseAbbrCode = convertToUpperCase(abbrCode);
                              setHospitalData((prev) => ({
                                ...prev,
                                abbrCode: uppercaseAbbrCode,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <label className="form-label">Trust</label>
                        <div className="form-control-wrap">
                          <TrustDropDown
                            value={hospitalData?.trust}
                            selectedId={hospitalData?.trust?.id}
                            onSelect={(trust) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                trust: trust,
                                invoiceTypes: trust?.invoiceTypes,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <label className="form-label">
                          Type <span className="text-danger">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <ClientType
                            value={hospitalData?.isHomeCare}
                            onSelect={(cType) => {
                              setHospitalData((prev) => ({ ...prev, isHomeCare: cType }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <label className="form-label">
                          Agency <span className="text-danger">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <AgencyDropdown
                            value={hospitalData?.agency ?? ""}
                            isMulti={true}
                            onSelect={(agency) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                agency: agency,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <FormInput
                            label={"Reference Number"}
                            type={FormInputType.default}
                            value={hospitalData?.referenceNo ?? ""}
                            placeholder={"Reference Number"}
                            onTextChange={(referenceNo) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                referenceNo: referenceNo,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mt-4">
                  <p className="fs-5">Other</p>
                  <Row className={"border rounded-2 mx-auto py-1"}>
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <label className="form-label">
                          Invoice Types <span className="text-danger">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <InvoiceTypeDropDown
                            isMulti={true}
                            isClearable={false}
                            value={hospitalData?.invoiceTypes}
                            isDisabled={hospitalData?.trust ? true : false}
                            onSelect={(invoice) => {
                              setHospitalData((prev) => ({ ...prev, invoiceTypes: invoice }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <label className="form-label">
                          Area Manager <span className="text-danger">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <BranchDropDown
                            value={hospitalData?.branch}
                            selectedId={hospitalData?.branch?.id}
                            onSelect={(branch) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                branch: branch,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    {/* <Col md="6" className={"mb-2"}>
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <FormInput
                              maxLength={255}
                              label={"Address"}
                              type={FormInputType.default}
                              value={hospitalData?.address ?? ""}
                              required={true}
                              placeholder={"Address"}
                              onError={(isError) => {
                                setHospitalData((prev) => ({
                                  ...prev,
                                  isNameError: isError,
                                }));
                              }}
                              onTextChange={(address) => {
                                setHospitalData((prev) => ({
                                  ...prev,
                                  address: address,
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="6" className={"mb-2"}>
                        <div className="form-group">
                          <div className="form-control-wrap">
                            <FormInput
                              label={"City"}
                              required={true}
                              type={FormInputType.name}
                              value={hospitalData?.city ?? ""}
                              placeholder={"City"}
                              onError={(isError) => {
                                setHospitalData((prev) => ({
                                  ...prev,
                                  isNameError: isError,
                                }));
                              }}
                              onTextChange={(city) => {
                                setHospitalData((prev) => ({
                                  ...prev,
                                  city: city,
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </Col> */}
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <FormInput
                            label={"Postal Code"}
                            type={FormInputType.postalCode}
                            // required={true}
                            value={hospitalData?.postalCode ?? ""}
                            placeholder={"Postalcode"}
                            onError={(isError) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                isPostalCodeError: isError,
                              }));
                            }}
                            onTextChange={(postalCode) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                postalCode: postalCode,
                              }));
                            }}
                            showSearchButton={true} // Show the search button
                            onSearch={handleSearch} // Provide the search callback
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">
                          Address <span className="text-danger">*</span>
                        </label>
                        <div className="form-control-wrap">
                          <AddressDropdown
                            search={pincode}
                            value={{ label: hospitalData?.address, value: hospitalData?.address }}
                            onSelect={(e) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                address: e.label,
                                city: e.value.city,
                                postalCode: e.value.zipCode,
                                coordinates: e.value.coordinates,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <PhoneInput
                            required
                            phone={hospitalData?.phoneNumber?.phoneNumber}
                            countryCode={hospitalData?.phoneNumber?.dialCode}
                            onChange={(phoneNumber) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                phoneNumber: phoneNumber,
                              }));
                            }}
                            onError={(isError) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                isPhoneNoError: isError,
                              }));
                            }}
                          />
                          {/* <FormInput
                            label={"Telephone"}
                            maxLength={10}
                            type={FormInputType.number}
                            required={true}
                            value={hospitalData?.telephone ?? ""}
                            placeholder={"Telephone"}
                            onTextChange={(telephone) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                telephone: telephone,
                              }));
                            }}
                          /> */}
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className={"mb-2"}>
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <FormInput
                            label={"Com Care Number"}
                            // required={true}
                            type={FormInputType.default}
                            value={hospitalData?.comCareNo ?? ""}
                            placeholder={"Com Care Number"}
                            // onError={(isError) => {
                            //   setHospitalData((prev) => ({
                            //     ...prev,
                            //     isPhoneError: isError,
                            //   }));
                            // }}
                            onTextChange={(comCareNo) => {
                              setHospitalData((prev) => ({
                                ...prev,
                                comCareNo: comCareNo,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className={"my-2"}>
                      <div className="form-group">
                        <label className="form-label">Is Mile Allowed?</label>
                        <div className="form-control-wrap">
                          <RadioButtonOptions
                            isTimesheet
                            size={4}
                            name="yesorno"
                            optionType={RadioOptionTypes.yesorno}
                            onChange={(index) => {
                              setHospitalData((prev) => ({ ...prev, isMileAllow: index === 0 }));
                            }}
                            activeIndex={hospitalData?.isMileAllow ? 0 : 1}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="6" className={"my-2"}>
                      <div className="form-group">
                        <label className="form-label">Is Priory Group?</label>
                        <div className="form-control-wrap">
                          <RadioButtonOptions
                            isTimesheet
                            size={4}
                            name="yesorNo"
                            optionType={RadioOptionTypes.yesorno}
                            onChange={(index) => {
                              setHospitalData((prev) => ({ ...prev, isPrimaryGroup: index === 0 }));
                            }}
                            activeIndex={hospitalData?.isPrimaryGroup ? 0 : 1}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mt-4">
                  <p className="fs-5">Contact</p>
                  {/* <div className={"border rounded-2 py-1"}>
                    {personFields?.map((person, index) => (
                      <React.Fragment key={index}>
                        <Row className="align-items-center align-items-baseline mt-3 position-relative mx-auto">
                          <Col md="3" className={"mb-2"}>
                            <div className="form-group">
                              <label className="form-label">
                                Contact Type <span className="text-danger">*</span>
                              </label>
                              <div className="form-control-wrap">
                                <PersonDropDown
                                  value={person?.personType}
                                  onSelect={(person) => {
                                    const updatedFields = [...personFields];
                                    updatedFields[index].personType = person;
                                    setPersonFields(updatedFields);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md="3" className={"mb-2"}>
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <FormInput
                                  label={"Name"}
                                  required={true}
                                  type={FormInputType.name}
                                  value={person?.personName ?? ""}
                                  placeholder={"Name"}
                                  onError={(isError) => {
                                    setHospitalData((prev) => ({
                                      ...prev,
                                      isNameError: isError,
                                    }));
                                  }}
                                  onTextChange={(value) => {
                                    const updatedFields = [...personFields];
                                    updatedFields[index].personName = value;
                                    setPersonFields(updatedFields);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md="3" className={"mb-2"}>
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <FormInput
                                  required={true}
                                  label={"Email"}
                                  type={FormInputType.email}
                                  value={person?.personEmail ?? ""}
                                  placeholder={"Email"}
                                  onError={(isError) => {
                                    const updatedFields = [...personFields];
                                    updatedFields[index].isEmailError = isError;
                                    setPersonFields(updatedFields);
                                  }}
                                  onTextChange={(value) => {
                                    const updatedFields = [...personFields];
                                    updatedFields[index].personEmail = value;
                                    setPersonFields(updatedFields);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md="3" className={"mb-2"}>
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <PhoneInput
                                  phone={person?.phone?.phoneNumber}
                                  countryCode={person?.phone?.dialCode}
                                  required={true}
                                  onChange={(data) => {
                                    const updatedFields = [...personFields];
                                    updatedFields[index].phone = data;
                                    setPersonFields(updatedFields);
                                  }}
                                  onError={(isError) => {
                                    const updatedFields = [...personFields];
                                    updatedFields[index].isPhoneError = isError;
                                    setPersonFields(updatedFields);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          {index === 0 && (
                            <span className="form-control-wrap position-absolute top-n5 right-14 text-end mx-auto me-n4">
                              <IconButton
                                type="button"
                                color="success"
                                icon="plus"
                                size={"sm"}
                                onClick={addPersonFields}
                              />
                            </span>
                          )}
                          {index > 0 && (
                            <span className="form-control-wrap position-absolute top-n5 right-14 text-end me-n4">
                              <IconButton
                                icon="minus"
                                size={"sm"}
                                color="danger"
                                onClick={() => handleRemovePersonField(index)}
                              ></IconButton>
                            </span>
                          )}
                        </Row>
                      </React.Fragment>
                    ))}
                  </div> */}

                  <div className={"border rounded-2 py-1"}>
                    {personFields?.length === 0
                      ? [
                          <React.Fragment key={0}>
                            <Row className="align-items-center align-items-baseline mt-3 position-relative mx-auto">
                              <Col md="3" className={"mb-2"}>
                                <div className="form-group">
                                  <label className="form-label">
                                    Contact Type <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-control-wrap">
                                    <PersonDropDown
                                      value={""}
                                      onSelect={(person) => {
                                        const updatedFields = [
                                          ...personFields,
                                          { personName: "", personEmail: "", phone: {}, personType: person },
                                        ];
                                        setPersonFields(updatedFields);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col md="3" className={"mb-2"}>
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <FormInput
                                      label={"Name"}
                                      required={true}
                                      type={FormInputType.name}
                                      value={""}
                                      placeholder={"Name"}
                                      onError={(isError) => {
                                        setHospitalData((prev) => ({
                                          ...prev,
                                          isNameError: isError,
                                        }));
                                      }}
                                      onTextChange={(value) => {
                                        const updatedFields = [
                                          ...personFields,
                                          { personName: value, personEmail: "", phone: {}, personType: "" },
                                        ];
                                        setPersonFields(updatedFields);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col md="3" className={"mb-2"}>
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <FormInput
                                      required={true}
                                      label={"Email"}
                                      type={FormInputType.email}
                                      value={""}
                                      placeholder={"Email"}
                                      onError={(isError) => {
                                        const updatedFields = [
                                          ...personFields,
                                          {
                                            personName: "",
                                            personEmail: "",
                                            phone: {},
                                            personType: "",
                                            isEmailError: isError,
                                          },
                                        ];
                                        setPersonFields(updatedFields);
                                      }}
                                      onTextChange={(value) => {
                                        const updatedFields = [
                                          ...personFields,
                                          { personName: "", personEmail: value, phone: {}, personType: "" },
                                        ];
                                        setPersonFields(updatedFields);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col md="3" className={"mb-2"}>
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <PhoneInput
                                      phone={""}
                                      countryCode={""}
                                      required={true}
                                      onChange={(data) => {
                                        const updatedFields = [
                                          ...personFields,
                                          { personName: "", personEmail: "", phone: data, personType: "" },
                                        ];
                                        setPersonFields(updatedFields);
                                      }}
                                      onError={(isError) => {
                                        const updatedFields = [
                                          ...personFields,
                                          {
                                            personName: "",
                                            personEmail: "",
                                            phone: {},
                                            personType: "",
                                            isPhoneError: isError,
                                          },
                                        ];
                                        setPersonFields(updatedFields);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <span className="form-control-wrap position-absolute top-n5 right-14 text-end mx-auto me-n4">
                                <IconButton
                                  type="button"
                                  color="success"
                                  icon="plus"
                                  size={"sm"}
                                  onClick={addPersonFields}
                                />
                              </span>
                            </Row>
                          </React.Fragment>,
                        ]
                      : personFields?.map((person, index) => (
                          <React.Fragment key={index}>
                            <Row className="align-items-center align-items-baseline mt-3 position-relative mx-auto">
                              <Col md="3" className={"mb-2"}>
                                <div className="form-group">
                                  <label className="form-label">
                                    Contact Type <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-control-wrap">
                                    <PersonDropDown
                                      value={person?.personType}
                                      onSelect={(selectedPerson) => {
                                        const updatedFields = [...personFields];
                                        updatedFields[index].personType = selectedPerson;
                                        setPersonFields(updatedFields);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col md="3" className={"mb-2"}>
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <FormInput
                                      label={"Name"}
                                      required={true}
                                      type={FormInputType.name}
                                      value={person?.personName ?? ""}
                                      placeholder={"Name"}
                                      onError={(isError) => {
                                        setHospitalData((prev) => ({
                                          ...prev,
                                          isNameError: isError,
                                        }));
                                      }}
                                      onTextChange={(value) => {
                                        const updatedFields = [...personFields];
                                        updatedFields[index].personName = value;
                                        setPersonFields(updatedFields);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col md="3" className={"mb-2"}>
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <FormInput
                                      required={true}
                                      label={"Email"}
                                      type={FormInputType.email}
                                      value={person?.personEmail ?? ""}
                                      placeholder={"Email"}
                                      onError={(isError) => {
                                        const updatedFields = [...personFields];
                                        updatedFields[index].isEmailError = isError;
                                        setPersonFields(updatedFields);
                                      }}
                                      onTextChange={(value) => {
                                        const updatedFields = [...personFields];
                                        updatedFields[index].personEmail = value;
                                        setPersonFields(updatedFields);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col md="3" className={"mb-2"}>
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <PhoneInput
                                      phone={person?.phone?.phoneNumber}
                                      countryCode={person?.phone?.dialCode}
                                      required={true}
                                      onChange={(data) => {
                                        const updatedFields = [...personFields];
                                        updatedFields[index].phone = data;
                                        setPersonFields(updatedFields);
                                      }}
                                      onError={(isError) => {
                                        const updatedFields = [...personFields];
                                        updatedFields[index].isPhoneError = isError;
                                        setPersonFields(updatedFields);
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              {index === 0 && (
                                <span className="form-control-wrap position-absolute top-n5 right-14 text-end mx-auto me-n4">
                                  <IconButton
                                    type="button"
                                    color="success"
                                    icon="plus"
                                    size={"sm"}
                                    onClick={addPersonFields}
                                  />
                                </span>
                              )}
                              {index > 0 && (
                                <span className="form-control-wrap position-absolute top-n5 right-14 text-end me-n4">
                                  <IconButton
                                    icon="minus"
                                    size={"sm"}
                                    color="danger"
                                    onClick={() => handleRemovePersonField(index)}
                                  ></IconButton>
                                </span>
                              )}
                            </Row>
                          </React.Fragment>
                        ))}
                  </div>
                </div>
                <Col md="6" className={"my-2"}>
                  <div className="form-group">
                    <label className="form-label">Status</label>
                    <div className="form-control-wrap">
                      <RadioButtonOptions
                        size={4}
                        name="status"
                        optionType={RadioOptionTypes.status}
                        onChange={(index) => {
                          setHospitalData((prev) => ({ ...prev, isActive: index === 0 }));
                        }}
                        activeIndex={hospitalData?.isActive ? 0 : 1}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="12" className={"mb-2"}>
                  {/* <ul className="align-center justify-content-end flex-wrap flex-sm-nowrap gx-2 gy-2">
                    <li>
                      <BackButton cancel />
                    </li>
                    <li>
                      <ActionButton
                        title="Save"
                        loading={loading}
                        type={"submit"}
                        disabled={isDisable}
                        // onClick={() => onFormSubmit()}
                      />
                    </li>
                  </ul> */}
                  <ul className="align-center justify-content-between flex-wrap flex-sm-nowrap gx-2 gy-2">
                    <div>
                      {hospitalData?.lastModifiedTime && hospitalData?.lastModifiedBy?.firstName && (
                        <>
                          <span className="text-danger fs-6 me-1">*</span>
                          <span>
                            Last edited on {moment(hospitalData?.lastModifiedTime).format("DD-MM-YYYY")} at {""}
                            {moment(hospitalData?.lastModifiedTime).format("HH:mm")} by{" "}
                            {hospitalData?.lastModifiedBy?.firstName + " " + hospitalData?.lastModifiedBy?.lastName}.
                          </span>
                        </>
                      )}
                    </div>
                    <div className="d-flex align-items-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <BackButton cancel />
                      </li>
                      <li>
                        <ActionButton
                          title="Save"
                          loading={loading}
                          type={"submit"}
                          disabled={isDisable}
                          // onClick={() => onFormSubmit()}
                        />
                      </li>
                    </div>
                  </ul>
                </Col>
              </Form>
            </PreviewAltCard>
          </BlockHead>
        </Block>
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default AddHospital;
