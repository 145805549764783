import React, { forwardRef, useImperativeHandle, useState } from "react";
import { ConfirmationAlert, LoadingComponent, ReactDataTable } from "../../../components/Component";
import { invoiceTableData, listWageInvoiceAPI, resendInvoiceEmailAPI } from "./InvoiceTableData";
import InvoiceEmail from "./invoiceEmail/InvoiveEmail";
import { InvoiceStatusOption } from "../../../utils/Utils";
import { PER_PAGE_ITEMS, showErrorToast } from "../../../utils";
import { useEffect } from "react";
import { UserPermissionType, isCheckToList } from "../../../services/permission/permission";
import PrepareInvoiceModal from "./PrepareInvoiceModal";
import { scrollToTop, showSuccessToast } from "../../../utils/Functions";
import PDFViewModal from "../../../components/PDFViewModal/PDFViewModal";
import InvoiceAuditModal from "./invoiceEmail/InvoiceAuditModal";

export const InvoiceType = {
  default: "default",
  hospital: "hospital",
  carer: "carer",
};

const InvoiceList = forwardRef(function WageList(
  {
    startDate,
    endDate,
    status,
    searchText,
    clientId,
    carerId,
    type,
    onRowSelectionChange,
    selectedRows,
    handleClearRows,
  },
  ref
) {
  const [modal, setModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [auditTrailModal, setAuditTrailModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [invoiceList, setInvoiceList] = useState([]);
  const [sortId, setSortId] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [prepareModal, setPrepareModal] = useState(false);
  const [invoiceIds, setInvoiceIds] = useState([]);

  useEffect(() => {
    loadInvoiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    // if (initScreen) return;
    if (currentPage === 1) {
      if (startDate && endDate) {
        loadInvoiceList();
      }
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, searchText, startDate, endDate, clientId, type, sortId, sortOrder]);

  useImperativeHandle(
    ref,
    () => {
      return {
        sendEmail() {
          const clientIds = selectedRows.map((row) => row.client.id);

          // Check if all client IDs are the same
          const areAllClientIdsSame = clientIds.every((id) => id === clientIds[0]);
          if (areAllClientIdsSame) {
            setSelectedItem(selectedRows);
            setModal(true);
          } else {
            showErrorToast("Please select rows with the same Client");
          }
        },
        reSendEmail() {
          const invoiceIds = selectedRows.map((row) => row.id);

          ConfirmationAlert("Are you sure!", "Do you want to resend the email?", () => {
            resendInvoiceEmailAPI(
              {
                invoiceIds: invoiceIds,
              },
              (data, error) => {
                if (error === null) {
                  showSuccessToast("Email has been successfully resent!");
                  loadInvoiceList();
                } else {
                  showErrorToast(error.message);
                }
              }
            );
          });
        },
        sendPrepared() {
          setSelectedItem(selectedRows);
          let invoiceIds = selectedRows?.map((item) => item.id);
          // sendPrepared(invoiceIds);
          setPrepareModal(true);
          setInvoiceIds(invoiceIds);
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows]
  );

  const onEmail = (row) => {
    setSelectedItem(row);
    setModal(true);
  };
  const onResendEmail = (row) => {
    setSelectedItem(row);

    ConfirmationAlert("Are you sure!", "Do you want to resend the email?", () => {
      resendInvoiceEmailAPI(
        {
          invoiceIds: [row?.id],
        },
        (data, error) => {
          if (error === null) {
            showSuccessToast("Email has been successfully resent!");
            loadInvoiceList();
          } else {
            showErrorToast(error.message);
          }
        }
      );
    });
  };
  const onPrepare = (ids) => {
    // sendPrepared(ids);
    setPrepareModal(true);
    setInvoiceIds(ids);
    // setSelectedItem(row);
    // setModal(true);
  };

  const onView = (row) => {
    setSelectedItem(row);
    setShowInvoiceModal(true);
  };

  const onAuditTrail = (row) => {
    setSelectedItem(row);
    setAuditTrailModal(true);
  };

  function loadInvoiceList() {
    scrollToTop();

    if (isCheckToList(UserPermissionType.invoice)) {
      let params = {
        search: searchText,
        startDate: startDate,
        endDate: endDate,
        clientId: clientId,
        // carerId: carerId,
        status: !type ? status?.value ?? InvoiceStatusOption.pending : "",
        pageInfo: {
          pageIndex: currentPage,
          itemsPerPage: PER_PAGE_ITEMS,
        },
        sorting: {
          field: sortId ?? null,
          order: sortOrder ?? null,
        },
      };
      if (!type) {
        if (!startDate || !endDate) {
          setInitScreen(false);
          return;
        }
      }
      if (searchText) {
        setInitScreen(false);
      } else {
        setInitScreen(true);
      }
      listWageInvoiceAPI(params, (list, error) => {
        if (error === null) {
          setInvoiceList(list?.list);
          setSelectedItem();
          handleClearRows();
          onRowSelectionChange();
          if (currentPage === 1) {
            setTotalItems(list?.pageInfo?.totalItems ?? 0);
          }
        }
        setInitScreen(false);
      });
    }
  }

  return (
    <React.Fragment>
      <ReactDataTable
        data={invoiceList}
        columns={invoiceTableData(type, status, onEmail, onResendEmail, onPrepare, onView, onAuditTrail)}
        isAllowed={isCheckToList(UserPermissionType.invoice)}
        totalItemCount={totalItems}
        pagination
        onPageChanged={(page, count) => {
          setCurrentPage(page);
        }}
        // selectableRows={status?.value === InvoiceStatusOption.sent ? false : true}
        selectableRows={true}
        onRowSelected={(row) => {
          onRowSelectionChange(row?.selectedRows);
        }}
        onSort={(id) => {
          setSortId(id);
          const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
          setSortOrder(newSortOrder);
        }}
      />
      <PrepareInvoiceModal
        visible={prepareModal}
        invoiceIds={invoiceIds}
        onSuccess={() => loadInvoiceList()}
        onClosed={() => {
          setPrepareModal(false);
          setInvoiceIds([]);
          setSelectedItem();
        }}
      />
      <InvoiceEmail
        visible={modal}
        item={selectedItem}
        onClosed={() => {
          setModal(false);
          //  setSelectedItem();
        }}
        onSaved={() => {
          loadInvoiceList();
          setSelectedItem();
          onRowSelectionChange();
          handleClearRows();
        }}
      />
      <PDFViewModal
        visible={showInvoiceModal}
        url={selectedItem?.invoiceFile?.fileUrl}
        onClosed={() => {
          setShowInvoiceModal(false);
        }}
      />
      <InvoiceAuditModal
        visible={auditTrailModal}
        item={selectedItem}
        onClosed={() => {
          setAuditTrailModal(false);
          setSelectedItem();
        }}
      />

      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
});

export default InvoiceList;
