import moment from "moment";
import { MoreOptions, TableTitle } from "../../../components/Component";
import { CURRENCY_CODE, InvoiceTypes, SERVER_DATE_FORMAT, dateToShow } from "../../../utils";
import { APIServicePath } from "../../../services/api/api-service-impl";
import { apiService } from "../../../services/api";
import { toPageInfo } from "../../_common/DataConverter";
import { openLinkInNewTab } from "../../../utils/Functions";
import { InvoiceFileType, InvoiceStatusOption, TimeSheetType } from "../../../utils/Utils";
import { UserActionType, UserPermissionType, isAllowToAccess } from "../../../services/permission/permission";

export const invoiceData = () => [
  {
    carerName: "Jack Adams",
    client: "Jack Smith",
    number: "#2345",
    branch: "Urban hospital",
    totalHours: "11",
    invoiceType: "PO",
    totalAmount: "1,10,000",
    fromDate: new Date(),
    date: new Date(),
    toDate: new Date(),
    status: true,
  },
  {
    carerName: "George Burton",
    client: "GeorgSmithton",
    number: "#2345",
    branch: "Sterling hospital",
    totalHours: "11",
    invoiceType: "PO",
    totalAmount: "1,10,000",
    fromDate: new Date(),
    date: new Date(),
    toDate: new Date(),
    status: true,
  },
  {
    carerName: "James Bradley",
    client: "JamesSmithley",
    number: "#2345",
    branch: "GMERS Hospital",
    totalHours: "11",
    invoiceType: "PO",
    totalAmount: "1,10,000",
    fromDate: new Date(),
    date: new Date(),
    toDate: new Date(),
    status: true,
  },
];

export const invoiceTableData = (type, status, onEmail, onResendEmail, onPrepare, onView, onAuditTrail) => {
  return [
    {
      name: <TableTitle title={"Invoice No"} />,
      width: "150px",
      selector: (row) => "#" + row?.id,
      sortable: false,
    },
    {
      name: <TableTitle title={"Client"} />,
      id: "client name",
      selector: (row) => row?.client?.name,
      omit: type === TimeSheetType.hospital ? true : false,
      sortable: true,
      sortFunction: (rowA, rowB) => 0,
      grow: 1,
    },
    {
      name: <TableTitle title={"Invoice Date"} />,
      id: "invoice date",
      selector: (row) => dateToShow(row?.invoiceDate),
      sortable: true,
      sortFunction: (rowA, rowB) => 0,
      omit: status?.value === InvoiceStatusOption.pending,
    },
    // {
    //   name: <TableTitle title={"From Date"} />,
    //   selector: (row) => dateToShow(row?.fromDate),
    //   sortable: false,
    // },
    // {
    //   name: <TableTitle title={"To Date"} />,
    //   selector: (row) => dateToShow(row?.toDate),
    //   sortable: false,
    // },
    {
      name: <TableTitle title={"Total Hours"} />,
      id: "total hours",
      selector: (row) => row?.totalHours,
      // width: "100px",
      sortable: true,
      sortFunction: (rowA, rowB) => 0,
    },
    {
      name: <TableTitle title={"Total Amount"} />,
      id: "total amount",
      selector: (row) => (row?.totalAmount ? CURRENCY_CODE + row?.totalAmount : "-"),
      sortable: true,
      sortFunction: (rowA, rowB) => 0,
      // width: "100px",
    },
    // {
    //   name: <TableTitle title={"Created By"} />,
    //   selector: (row) => row?.created?.name,
    //   sortable: true,
    //   omit: status?.value !== InvoiceStatusOption.pending,
    // },
    {
      name: <TableTitle title={"Prepared By"} />,
      selector: (row) => row?.preparedBy?.name,
      sortable: false,
      omit: status?.value !== InvoiceStatusOption.prepared,
    },
    {
      name: <TableTitle title={"Sent By"} />,
      selector: (row) => row?.sentBy?.name,
      sortable: false,
      omit: status?.value !== InvoiceStatusOption.sent,
    },
    // {
    //   name: <TableTitle title={"Invoice Type"} />,
    //   selector: (row) => row?.invoiceType?.label ?? "",
    //   sortable: false,
    // },
    // {
    //   name: <TableTitle title={"Status"} />,
    //   // selector: (row) => (row.status ? "Active" : "Inactive"),
    //   width: "100px",
    //   sortable: true,
    // },
    {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "100px",
      cell: (row) => (
        <>
          <MoreOptions
            options={[
              {
                icon: "file",
                text: "Prepare Invoice",
                action: () => {
                  onPrepare([row?.id]);
                },
                hide:
                  row?.status !== InvoiceStatusOption.pending ||
                  !isAllowToAccess(UserPermissionType.invoice, UserActionType.prepare),
              },
              {
                icon: "emails",
                text: "Send Email",
                action: () => {
                  onEmail(row);
                },
                hide:
                  row?.status !== InvoiceStatusOption.prepared ||
                  !isAllowToAccess(UserPermissionType.invoice, UserActionType.sent),
              },
              {
                icon: "send",
                text: "Resend Email",
                action: () => {
                  onResendEmail(row);
                },
                hide:
                  row?.status === InvoiceStatusOption.pending ||
                  row?.status === InvoiceStatusOption.prepared ||
                  !isAllowToAccess(UserPermissionType.invoice, UserActionType.sent),
              },
              {
                icon: "file-download",
                text: "Download Invoice",
                action: () => {
                  openLinkInNewTab(row?.invoiceFile?.fileUrl);
                },
                hide: row?.status === InvoiceStatusOption.pending,
              },
              {
                icon: "eye",
                text: "View Invoice",
                action: () => {
                  onView(row);
                },
                hide: row?.status === InvoiceStatusOption.pending,
              },
              {
                icon: "swap",
                text: "Audit Trail",
                action: () => {
                  onAuditTrail(row);
                },
                hide:
                  row?.status === InvoiceStatusOption.pending ||
                  row?.status === InvoiceStatusOption.prepared ||
                  !isAllowToAccess(UserPermissionType.invoice, UserActionType.sent),
              },
            ]}
          />
        </>
      ),
    },
  ];
};

export function sentInvoiceEmailAPI(data, templateFileIds, invoiceFileIds, newFileIds, callback) {
  let params = {
    templateId: 0,
    clientId: 0,
    toEmails: data?.toEmail,
    invoiceIds: data?.invoiceIds,
    subject: data?.subject,
    message: data?.description,
    templateFileIds: templateFileIds,
    invoiceFileIds: invoiceFileIds,
    newFileIds: newFileIds,
  };
  apiService
    .postCall(APIServicePath.invoiceSend, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function customInvoiceAPI(data, custInvoiceData, fileId, callback) {
  let params = {
    clientId: data?.client?.id,
    invoiceDate: moment(data?.invoiceDate).format(SERVER_DATE_FORMAT),
    dueDate: moment(data?.dueDate).format(SERVER_DATE_FORMAT),
    fromDate: moment(data?.fromDate).format(SERVER_DATE_FORMAT),
    toDate: moment(data?.toDate).format(SERVER_DATE_FORMAT),
    invoiceNo: data?.invoiceNumber,
    poNumber: data?.poNum,
    custInvoiceData: custInvoiceData,
    fileIds: fileId,
  };
  apiService
    .postCall(APIServicePath.customInvoice, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function sentEmailAPI(data, fileIds, newFileIds, callback) {
  let params = {
    to: data?.toEmail,
    subject: data?.subject,
    body: data?.description,
    fileIds: fileIds ?? [],
    newFileIds: newFileIds ?? [],
  };
  apiService
    .postCall(APIServicePath.sentEmailNotification, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function moveToPrepareAPI(invoiceIds, invoiceDate, dueDate, callback) {
  let params = {
    invoiceIds: invoiceIds,
    invoiceDate: invoiceDate ? moment(invoiceDate).format("YYYY-MM-DD") : null,
    dueDate: dueDate ? moment(dueDate).format("YYYY-MM-DD") : null,
  };
  apiService
    .postCall(APIServicePath.moveToPrepared, params, 50000)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function invoiceContactListApi(clientId, callback) {
  let params = {
    clientId: clientId,
  };
  apiService
    .postCall(APIServicePath.getContactList, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function listWageInvoiceAPI(params, callback) {
  apiService
    .postCall(APIServicePath.wageInvoiceList, params)
    .then((data) => {
      let list = toWageInvoiceList(data?.list);
      let pageInfo = toPageInfo(data?.pageInfo);
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function exportInvoiceList(params, callback) {
  apiService
    .postCall(APIServicePath.exportInvoice, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function resendInvoiceEmailAPI(params, callback) {
  apiService
    .postCall(APIServicePath.resendInvoiceEmail, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function exportInvoiceListXLSX(params, callback) {
  apiService
    .postCall(APIServicePath.exportInvoiceXLSX, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function invoiceEmailAuditList(params, callback) {
  apiService
    .postCall(APIServicePath.invoiceEmailAuditList, params)
    .then((data) => {
      callback(data?.list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//Converter
function toWageInvoiceList(data) {
  let list = [];
  data?.forEach((element) => {
    list.push(toWageInvoice(element));
  });
  return list;
}

function toWageInvoice(data) {
  let types = InvoiceTypes.filter((item) => item.value === data?.invoiceType);
  let invoiceFile = { ...data?.fileData };
  invoiceFile.fileType = InvoiceFileType.invoice;

  return {
    id: data?.id,
    client: data?.client,
    carerName: data?.carer?.firstName + " " + data?.carer?.lastName,
    totalAmount: data?.totalAmount,
    totalHours: data?.totalHours,
    invoiceDate: data?.invoiceDate ? moment(data.invoiceDate).format("YYYY-MM-DD") : null,
    invoiceFile: invoiceFile ? invoiceFile : [],
    fromDate: data?.fromDate ? moment(data.fromDate).format("YYYY-MM-DD") : null,
    toDate: data?.toDate ? moment(data.toDate).format("YYYY-MM-DD") : null,
    status: data?.status,
    invoiceType: types.length === 1 ? types[0] : null,
    sentBy: data?.sentBy
      ? {
          id: data?.sentBy?.id,
          name: data?.sentBy?.firstName + " " + data?.sentBy?.lastName,
        }
      : null,
    preparedBy: data?.preparedBy
      ? {
          id: data?.preparedBy?.id,
          name: data?.preparedBy?.firstName + " " + data?.preparedBy?.lastName,
        }
      : null,
  };
}
