import React, { useEffect, useState } from "react";
import { Card, Form } from "reactstrap";
import { AnnsModal, Col, Icon, LinkItem } from "../../../../components/Component";
import { invoiceEmailAuditList } from "../InvoiceTableData";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const InvoiceAuditModal = ({ visible, item, onClosed }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [emailAuditList, setEmailAuditList] = useState([]);

  useEffect(() => {
    if (visible && item) {
      loadEmailAuditList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, visible]);

  const close = () => {
    onClosed();
    setLoading(false);
  };

  const onMove = (item) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/invoice-audit-detail`,
      state: { data: item },
    });
  };

  function loadEmailAuditList() {
    let params = {
      invoiceId: item?.id,
    };
    invoiceEmailAuditList(params, (data, error) => {
      if (error === null) {
        setLoading(false);
        setEmailAuditList(data);
      } else {
        setLoading(false);
      }
    });
  }

  return (
    <>
      <AnnsModal
        size={"xl"}
        isOpen={visible}
        toggle={() => close()}
        title={`Invoice Audit Trail`}
        loading={loading}
        isFooterHide
      >
        <Form className="row gy-4 form-validation">
          <Col md="12">
            <Card className="card-bordered mt-2">
              {emailAuditList?.length > 0 ? (
                <div className="px-2">
                  <div className="align-items-center">
                    <div className="row">
                      <div className="col-sm-3 col-12 py-1">
                        <p className="mb-0">Date & Time</p>
                      </div>
                      <div className="col-sm-4 col-12 py-1">
                        <p className="mb-0">From</p>
                      </div>
                      <div className="col-sm-4 col-12 py-1">
                        <p className="mb-0">To</p>
                      </div>
                      <div className="col-sm-1 col-12 py-1">
                        <p className="mb-0"></p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="border-card-end"></div>
              <div className="px-2">
                <div className="align-items-center">
                  {emailAuditList?.length > 0 ? (
                    emailAuditList?.map((item, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="row align-center"
                          style={{
                            borderBottom: index !== emailAuditList.length - 1 ? "1px solid #dee2e6" : "none",
                            paddingBottom: "10px",
                          }}
                        >
                          <div className="col-sm-3 col-12 py-2">
                            <p className="mb-0">
                              {moment(item?.sentDateTime).format("DD-MM-YYYY")} at {""}
                              {moment(item?.sentDateTime).format("HH:mm")}
                            </p>
                          </div>
                          <div className="col-sm-4 col-12 py-2">
                            <p className="mb-0">{item?.sentBy?.firstName + " " + item?.sentBy?.lastName ?? "-"}</p>
                          </div>
                          <div className="col-sm-4 col-12 py-2">
                            {item?.toEmails?.map((email, emailIndex) => (
                              <p key={emailIndex} className="mb-0">
                                {email.e}
                              </p>
                            ))}
                          </div>
                          <div className="col-sm-1 col-12 py-2">
                            <p className="mb-0 cursor-pointer" onClick={() => onMove(item)}>
                              <Icon name="eye" style={{ fontSize: "17px" }}></Icon>
                            </p>
                          </div>
                        </div>
                      </React.Fragment>
                    ))
                  ) : (
                    <div className="">
                      <div className="py-2">
                        <p className="text-center">There are no records found</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </Col>
        </Form>
      </AnnsModal>
    </>
  );
};

export default InvoiceAuditModal;
