import { Redirect } from "react-router-dom";
import { Icon } from "../components/Component";
import moment from "moment";
import { toast } from "react-toastify";
import { AppText } from "./AppText";
import { DATE_FORMAT } from ".";
import { ColorsWithHexCode } from "./Utils";

export const RedirectAs404 = ({ location }) => (
  <Redirect to={Object.assign({}, location, { state: { is404: true } })} />
);

// Set deadlines for projects
export const setDeadlineDays = (deadline) => {
  var currentDate = new Date();
  var difference = deadline.getTime() - currentDate.getTime();
  var days = Math.ceil(difference / (1000 * 3600 * 24));
  return days;
};

// Function to structure date ex: YYYY-MM-DD
export const setDateForPicker = (rdate) => {
  let d = rdate.getDate();
  d < 10 && (d = "0" + d);
  let m = rdate.getMonth() + 1;
  m < 10 && (m = "0" + m);
  let y = rdate.getFullYear();
  rdate = y + "-" + m + "-" + d;

  return rdate;
};

//Function that returns the first or first two letters from a name
export const findUpper = (string) => {
  let extractedString = [];

  for (var i = 0; i < string.length; i++) {
    if (string.charAt(i) === string.charAt(i).toUpperCase() && string.charAt(i) !== " ") {
      extractedString.push(string.charAt(i));
    }
  }
  if (extractedString.length > 1) {
    return extractedString[0] + extractedString[1];
  } else {
    return extractedString[0];
  }
};

export function windowReload() {
  window.history.pushState(
    `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
    "auth-login",
    `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
  );
  window.location.reload();
}

export const removeTimeFromServerDateString = (dateString) => {
  let date = dateString?.replace("T00:00:00.000Z", "") ?? "";
  return date;
};

export const dateStringToShowFormat = (date, format = DATE_FORMAT) => {
  let dueDate = moment(removeTimeFromServerDateString(date), "YYYY-MM-DD");
  return dueDate.format(format);
};

export const dateToShow = (date, format = DATE_FORMAT) => {
  return moment(date).format(format);
};

export const isNumberOnly = (value) => {
  return !isNaN(+value);
};

export const covertToNumberOnly = (value) => {
  return value?.replaceAll(/[^\d.]/g, "");
};

export const toCapitalize = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
};

export const convertToUpperCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toUpperCase();
};

export const openLinkInNewTab = (url, event) => {
  event?.preventDefault();
  if (url === null || url === undefined) {
    return;
  }
  if (event?.metaKey) {
    window.open(url, "_blank");
  } else {
    window.open(url, "_blank");
  }
};

export const showErrorToast = (message) => {
  let text = message.length > 0 ? message : AppText.connectError;
  toast.error(text, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: <CloseButton />,
  });
};

export const showSuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    closeButton: <CloseButton />,
  });
};
const CloseButton = () => {
  return (
    <span className="btn-trigger toast-close-button" role="button">
      <Icon name="cross"></Icon>
    </span>
  );
};

export function formatPhoneNumber(prefix, value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) {
    return value;
  }

  if (value === prefix) {
    return "";
  }
  var phoneNumber = value.replace(prefix, "");
  // clean the input for any non-digit values.
  phoneNumber = phoneNumber.replace(/[^\d]/g, "");
  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) {
    return prefix + phoneNumber.slice(0);
  }

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `${prefix}${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `${prefix}${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 10)}`;
}

export function formatPostalCode(prefix, value) {
  // If input value is falsy, e.g., if the user deletes the input, then just return
  if (!value) {
    return value;
  }

  // Remove the prefix from the value
  var postalCode = value.replace(prefix, "");

  // Clean the input for any non-alphanumeric values
  postalCode = postalCode.replace(/[^A-Za-z0-9]/g, "");

  // Apply the format based on the given regex pattern
  postalCode = postalCode.replace(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s?(\d[A-Z]{2})$/i, "$1 $2");

  // Apply minimum and maximum length constraints
  const minLength = 5; // Minimum length requirement
  const maxLength = 8; // Maximum length requirement

  if (postalCode.length < minLength) {
    return prefix + postalCode;
  } else if (postalCode.length > maxLength) {
    postalCode = postalCode.slice(0, maxLength);
  }

  // Add the prefix back to the formatted postal code
  return prefix + postalCode;
}

export const convertDropValue = (item, isCapitalized = false) => {
  if (!item) return null;
  let data = item?.name ?? item?.title ?? "";
  let label = isCapitalized ? toCapitalize(data) : data;
  let value = {
    id: item?.id,
    value: data,
    label: label,
  };
  return value;
};
export const convertYearsDropValue = (item) => {
  if (!item) return null;

  let value = {
    id: item?.id,
    value: item?.name ?? item?.title ?? "",
    label: item?.name ?? item?.title ?? "",
  };
  return value;
};

export const convertColorToHex = (colorTitle) => {
  // Find the color object that has the given colorTitle as label (partial match)
  const matchedColorObject = ColorsWithHexCode.find((color) => color.label === colorTitle);

  // Get the hex code based on the matched color object
  const hexCode = matchedColorObject ? matchedColorObject.value : null;

  return hexCode;
};

export const convertHexToColor = (hexCode) => {
  // Find the color object that has the given hexCode as value
  const matchedColorObject = ColorsWithHexCode.find((color) => color.value === hexCode);

  // Get the color title based on the matched color object
  const colorTitle = matchedColorObject ? matchedColorObject.label : null;

  return colorTitle;
};

export const convertGradeColor = (item) => {
  if (!item) return null;
  let value = {
    id: item?.id,
    value: item?.title,
    label: (
      <>
        <Icon name={`square-fill text-${item?.title}`}></Icon>
        <span>{toCapitalize(item?.title)}</span>
      </>
    ),
  };
  return value;
};

export const convertDropMultiValue = (items) => {
  let list = [];
  items.map((item, index) => {
    let value = {
      id: item?.id,
      value: item?.name ?? item?.title ?? "",
      label: item?.name ?? item?.title ?? "",
    };
    return list.push(value);
  });
  return list;
};

export const convertNameToDropdownList = (items) => {
  let list = [];
  items.map((item, index) => {
    let value = {
      id: item?.id,
      value: item?.firstName + " " + item?.lastName,
      label: item?.firstName + " " + item?.lastName,
    };
    return list.push(value);
  });
  return list;
};

export const updateTimeFromShift = (shiftType, shiftTime) => {
  if (shiftType) {
    const selectedShift = shiftTime?.find((shift) => shift.shiftType === shiftType.value);
    if (selectedShift) {
      const updatedStartTime = moment(selectedShift.startTime, "HH:mm:ss").utc().toDate();
      const updatedEndTime = moment(selectedShift.endTime, "HH:mm:ss").utc().toDate();
      return {
        startTime: updatedStartTime,
        endTime: updatedEndTime,
      };
    }
  }
  return {
    startTime: null,
    endTime: null,
  };
};

export const convertToNotNull = (object) => {
  Object.keys(object).forEach((key) => {
    if (!object[key]) {
      delete object[key];
    }
  });
  return object;
};

export function formatUKPhoneNumber(countryCode, phoneNumber) {
  // If either the country code or phone number is falsy, return an empty string
  if (!countryCode || !phoneNumber) {
    return "";
  }

  // Clean the phone number for any non-digit characters.
  const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, "");

  // Get the length of the cleaned phone number
  const phoneNumberLength = cleanedPhoneNumber.length;

  // Check if the phone number is empty after cleaning
  if (phoneNumberLength === 0) {
    return "";
  }

  // Define the maximum lengths for area code and local number
  const maxAreaCodeLength = 4;
  const maxLocalNumberLength = 8;

  // Initialize variables to store the formatted area code and local number
  let formattedAreaCode = "";
  let formattedLocalNumber = "";

  // Format the area code (if applicable)
  if (phoneNumberLength > maxAreaCodeLength) {
    formattedAreaCode = cleanedPhoneNumber.slice(0, maxAreaCodeLength);
  } else {
    formattedAreaCode = cleanedPhoneNumber.slice(0, phoneNumberLength);
  }

  // Format the local number (if applicable)
  if (phoneNumberLength > maxAreaCodeLength) {
    formattedLocalNumber = cleanedPhoneNumber.slice(maxAreaCodeLength, maxAreaCodeLength + maxLocalNumberLength);
  } else {
    formattedLocalNumber = cleanedPhoneNumber.slice(formattedAreaCode.length);
  }

  // Return the formatted UK-style phone number with the country code
  return `+${countryCode} ${formattedAreaCode} ${formattedLocalNumber}`;
}

export const timeToMinutes = (timeString) => {
  const [hours, minutes] = timeString?.split(":");
  return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
};
export const minutesToTime = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};

export function getDates(startDate, endDate) {
  let dates = [];
  let currDate = moment(startDate).startOf("day");
  let lastDate = moment(endDate).startOf("day");

  dates.push(currDate.clone().toDate()); // Include the start date

  while (currDate.add(1, "days").diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  dates.push(lastDate.toDate()); // Include the end date
  return dates;
}

export const convertPoNumberDropValue = (item) => {
  if (!item) return null;
  let value = {
    id: item,
    value: item,
    label: item,
  };
  return value;
};

function getMins(startTime, endTime) {
  return Math.round(moment.duration(endTime.diff(startTime)).asMinutes());
}
export function calculateTotal(dayData, breakHourMinutes) {
  const startTime = dayData?.startTime ? moment(dayData.startTime) : null;
  const endTime = dayData?.endTime ? moment(dayData.endTime) : null;
  const sleepoverStart = dayData?.sleepoverStart ? moment(dayData.sleepoverStart) : null;
  const sleepoverEnd = dayData?.sleepoverEnd ? moment(dayData.sleepoverEnd) : null;
  const isSleepOver = dayData?.isSleepOver || false;

  if (!startTime || !endTime) {
    return "0:00";
  }

  if (isSleepOver && (!sleepoverStart || !sleepoverEnd)) {
    return "0:00"; // If sleepover is true but sleepover times are not provided, return "0:00"
  }

  let totalMinutes = 0;
  let totalHours = 0;
  let remainingMinutes = 0;
  if (!isSleepOver) {
    let mins = getMins(startTime, endTime);
    if (mins > 0) {
      totalMinutes = mins - breakHourMinutes;
    } else {
      totalMinutes = getMins(startTime, endTime.add(1440, "minutes")) - breakHourMinutes;
      // totalMinutes = endTime.add(1440, "minutes").diff(startTime, "minutes") - breakHourMinutes;
    }
  } else {
    let startToSleepoverStartMins = 0;
    let sleepoverStartToEndMins = 0;
    let sleepoverEndToEndMins = 0;

    // It is calculate mins for start time to sleepover start time.
    startToSleepoverStartMins = getMins(startTime, sleepoverStart);
    // startToSleepoverStartMins = sleepoverStart.diff(startTime, "minutes");
    if (startToSleepoverStartMins < 0) {
      startToSleepoverStartMins = getMins(startTime, sleepoverStart.add(1440, "minutes"));
      // startToSleepoverStartMins = sleepoverStart.add(1440, "minutes").diff(startTime, "minutes");
    }

    // It is calculate mins for sleepover start time to sleepover end time.
    sleepoverStartToEndMins = getMins(sleepoverStart, sleepoverEnd);
    // sleepoverStartToEndMins = sleepoverEnd.diff(sleepoverStart, "minutes");
    if (sleepoverStartToEndMins < 0) {
      sleepoverStartToEndMins = getMins(sleepoverStart, sleepoverEnd.add(1440, "minutes"));
      // sleepoverStartToEndMins = sleepoverEnd.add(1440, "minutes").diff(sleepoverStart, "minutes");
    }

    // It is calculate mins for sleepover end time to job end time.
    sleepoverEndToEndMins = getMins(sleepoverEnd, endTime);
    // sleepoverEndToEndMins = endTime.diff(sleepoverEnd, "minutes");
    if (sleepoverEndToEndMins < 0) {
      sleepoverEndToEndMins = getMins(sleepoverEnd, endTime.add(1440, "minutes"));
      // sleepoverEndToEndMins = endTime.add(1440, "minutes").diff(sleepoverEnd, "minutes");
    }

    totalMinutes = startToSleepoverStartMins + sleepoverStartToEndMins + sleepoverEndToEndMins - breakHourMinutes;
  }
  console.log("totalMinutes: ", totalMinutes);
  totalHours = Math.floor(totalMinutes / 60);
  remainingMinutes = totalMinutes % 60;
  return `${totalHours}:${remainingMinutes < 10 ? "0" : ""}${remainingMinutes}`;
}

// Helper function to convert total hours into "X hours Y minutes"
// Helper function to convert total hours into "H:MM" format
export function convertToHourMinute(totalHours) {
  if (typeof totalHours !== "number") {
    return "N/A"; // Agar input number nahi hai
  }

  // Poore hours ko extract karo
  const hours = Math.floor(totalHours);

  // Decimal part ko extract karo aur phir minutes mein convert karo
  const decimalPart = totalHours - hours;
  const minutes = Math.round(decimalPart * 60);

  // Minutes ko two-digit format mein convert karna
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // "H:MM" format mein return karo
  return `${hours}:${formattedMinutes}`;
}

export const numberFormatter = (prefix, value) => {
  if (value === undefined || value === null || value === "") {
    return "";
  }

  var number = value.replace(/[^-\d.]/g, "");
  number = parseFloat(number);
  let isNegative = number < 0.0;

  var amount = value.replace(/[^\d.]/g, "");
  amount = parseFloat(amount);
  let isDecimal = amount % 1 !== 0;
  amount =
    (isNegative ? "-" : "") + prefix + amount.toFixed(isDecimal ? 2 : 0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return amount;
};

export function calculatePercentageChange(data) {
  // Reverse the data array to get the data in descending order
  const reversedData = [...data].reverse();

  // Calculate the difference between the last week's total amount and the previous week's total amount
  const lastWeekAmount = reversedData[0]?.totalAmount - reversedData[1]?.totalAmount;

  // Calculate the percentage change
  const lastWeekpercentage = ((lastWeekAmount / reversedData[0]?.totalAmount) * 100).toFixed(2);

  return {
    lastWeekAmount,
    lastWeekpercentage,
  };
}

export function calculateSecondLastWeekPercentageChange(data) {
  const reversedData = [...data].reverse();
  const secondLastWeekAmount = reversedData[1]?.totalAmount - reversedData[2]?.totalAmount;
  const secondLastWeekPercentage = ((secondLastWeekAmount / reversedData[1]?.totalAmount) * 100).toFixed(2);

  return {
    secondLastWeekAmount,
    secondLastWeekPercentage,
  };
}

export function hextoBackgrondColor(color, percent) {
  // Check if the color is in hex format
  if (!color.startsWith("#")) {
    return color; // Return the original color if not in hex format
  }

  // Convert hex color to RGB
  function hexToRGB(hex) {
    return {
      r: parseInt(hex.slice(1, 3), 16),
      g: parseInt(hex.slice(3, 5), 16),
      b: parseInt(hex.slice(5, 7), 16),
    };
  }

  // Convert RGB to hex
  function RGBToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  }

  // Lighten the color
  const rgb = hexToRGB(color);
  const lightenFactor = 1 + percent; // Increase the RGB values to lighten the color
  const r = Math.min(Math.round(rgb.r * lightenFactor), 255);
  const g = Math.min(Math.round(rgb.g * lightenFactor), 255);
  const b = Math.min(Math.round(rgb.b * lightenFactor), 255);

  return RGBToHex(r, g, b);
}

const dayMapping = {
  SUN: "Sunday",
  MON: "Monday",
  TUE: "Tuesday",
  WED: "Wednesday",
  THU: "Thursday",
  FRI: "Friday",
  SAT: "Saturday",
};

export function covertFullDayName(day) {
  return dayMapping[day] || day;
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "instant" });
};

export const redirectToEmail = () => {
  window.location.href = `mailto:${AppText.SUPPORT_EMAIL}`;
};
