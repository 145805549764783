import React from "react";
import { useState } from "react";
import {
  ActionButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  FormInput,
  LoadingComponent,
  PaginationComponent,
  PreviewAltCard,
  Row,
  StatusActiveDropDown,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { listTrustAPI } from "./TrustTableData";
import { PER_PAGE_ITEMS, ServerStatusType } from "../../../../utils";
import { ProjectCard, ProjectHead } from "../../../../components/card/ProjectCard";
import { scrollToTop, showErrorToast, toCapitalize } from "../../../../utils/Functions";
import { ImportExportButton } from "../../../../components/button/ActionButton";
import { TrustStatusBadge } from "../../../../components/badge/StatusBadge";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../../services/permission/permission";

const TrustList = () => {
  const [trustList, setTrustList] = useState([]);
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [initScreen, setInitScreen] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    loadTrust();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (initScreen) return;
    if (currentPage === 1) {
      loadTrust();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, searchText]);

  const history = useHistory();

  function onEdit(row) {
    setSelectedItem(row);
    history.push({
      pathname: `${process.env.PUBLIC_URL}/add-trust`,
      state: { data: row }, // Pass the selected item as state
    });
  }

  function loadTrust() {
    scrollToTop();
    if (isCheckToList(UserPermissionType.trust)) {
      listTrustAPI(
        {
          status: status?.value ?? ServerStatusType.active,
          search: searchText,
          pageInfo: {
            pageIndex: currentPage,
            itemsPerPage: 20,
          },
        },
        (list, error) => {
          if (error === null) {
            setTrustList(list?.list);
            if (currentPage === 1) {
              setTotalItems(list?.pageInfo?.totalItems ?? 0);
            }
          }
          setInitScreen(false);
        }
      );
    } else {
      setInitScreen(false);
    }
  }

  const onAddClick = () => {
    history.push(`${process.env.PUBLIC_URL}/add-trust`);
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Trust" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Trust</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="d-flex align-items-center">
                <span className="me-2">
                  <FormInput
                    labelGroupClass="m-0"
                    value={searchText ?? ""}
                    placeholder="Search Trust"
                    onTextChange={(text) => setSearchText(text)}
                  />
                </span>
                <span className="me-2">
                  <StatusActiveDropDown
                    value={status}
                    className="w-125px "
                    onSelect={(e) => {
                      setStatus(e);
                    }}
                  />
                </span>
                {isAllowToAccess(UserPermissionType.trust, UserActionType.add) && (
                  <span>
                    <ImportExportButton addButton title={"Add"} onClick={onAddClick} iconName={"plus"} />
                  </span>
                )}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            {trustList?.length > 0 ? (
              trustList?.map((item, idx) => {
                const contact = item.personFields?.find((field) => field.personType.value === "CONTACT");
                return (
                  <Col sm="12" lg="4" xxl="3" key={idx}>
                    <ProjectCard>
                      <ProjectHead
                        text={(item?.name ? item.name.slice(0, 2) : "  ").toUpperCase()}
                        customHex={item?.colourHex}
                        name={toCapitalize(item?.name ?? " ")}
                        email={item?.email}
                        subName={item?.city}
                        statusBadge={
                          <>
                            <TrustStatusBadge status={item?.isActive} />
                          </>
                        }
                        phone={
                          "+" +
                          (contact?.countryCode ?? item.personFields[0]?.countryCode) +
                          "-" +
                          (contact?.phoneNumber ?? item.personFields[0]?.phoneNumber)
                        }
                        onEdit={() => onEdit(item)} // Pass a callback function
                        address={item?.address}
                      />
                    </ProjectCard>
                  </Col>
                );
              })
            ) : (
              <>
                <div className="text-center">
                  <span className="text-silent">There are no records found</span>
                </div>
              </>
            )}
          </Row>
          <Row className="mt-2">
            {totalItems > 0 ? (
              <PaginationComponent
                itemPerPage={20}
                totalItems={totalItems}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">{/* <span className="text-silent">There are no records found</span> */}</div>
            )}
          </Row>
          {!isCheckToList(UserPermissionType.trust) && (
            <Block className={"pt-1"}>
              <PreviewAltCard>
                <span className="d-flex justify-content-center">You do not have permission</span>
              </PreviewAltCard>
            </Block>
          )}
        </Block>
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default TrustList;
