import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  ActionButton,
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  LoadingComponent,
  PreviewCard,
} from "../../../../components/Component";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { iconsType } from "../../../../layout/fileIcon/FileIcon";
import PDFViewModal from "../../../../components/PDFViewModal/PDFViewModal";

const InvoiceAuditDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const invoiceAuditData = location.state?.data;
  const [initScreen, setInitScreen] = useState(false);
  const [viewPdfModal, setViewPdfModal] = useState(false);
  const [itemUrl, setItemUrl] = useState(null);

  useEffect(() => {
    if (!invoiceAuditData) {
      setInitScreen(true);
    } else {
      setInitScreen(false);
    }
  }, [invoiceAuditData]);

  const onView = (url) => {
    setViewPdfModal(true);
    setItemUrl(url);
  };

  const onBackInvoice = (url) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/invoices`,
      state: { isBackSent: true },
    });
  };

  return (
    <React.Fragment>
      <Head title="Invoice Audit Trail Detail" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Invoice Audit Trail Detail</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <BackButton
                onClick={() => {
                  onBackInvoice();
                }}
              />
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard>
            <div className="mt-1">
              <span className="fw-semibold fs-6">
                Invoice sent from {invoiceAuditData?.sentBy?.firstName + " " + invoiceAuditData?.sentBy?.lastName} to
                {" " +
                  invoiceAuditData?.toEmails
                    ?.filter((email) => email.e)
                    .map((email) => email.e)
                    .join(", ")}
              </span>

              <br />
              <p className="fw-bold fs-6 mt-3">Subject:- {invoiceAuditData?.subject}</p>
              <div className="px-2 mt-3">
                <div
                  className="py-2 details"
                  id="invoice-audit-details"
                  dangerouslySetInnerHTML={{
                    __html: invoiceAuditData?.messageBody,
                  }}
                ></div>
                <div>
                  {invoiceAuditData?.attachments.map((item, index) => (
                    <div key={index} className="nk-upload-item cursor-pointer" onClick={() => onView(item?.path)}>
                      <div className="nk-upload-icon">
                        {item?.isOldFile ? (
                          <>{iconsType[item?.contentType] ? iconsType[item?.contentType] : iconsType["others"]}</>
                        ) : (
                          <>{iconsType[item?.contentType] ? iconsType[item?.contentType] : iconsType["others"]}</>
                        )}
                      </div>
                      <div className="nk-upload-info">
                        <span>{item?.fileName ?? "N/A"}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </PreviewCard>
        </Block>
      </Content>
      <PDFViewModal
        visible={viewPdfModal}
        url={itemUrl}
        onClosed={() => {
          setViewPdfModal(false);
        }}
      />
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default InvoiceAuditDetail;
