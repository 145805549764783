import React, { useState } from "react";
import { AnnsModal, Col } from "../Component";
import { Form } from "reactstrap";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PDFViewModal = ({ visible, url, onClosed }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Initialize the default layout plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // Function to close the modal and reset states
  const close = () => {
    onClosed();
    setLoading(false);
    setError(false);
  };

  return (
    <AnnsModal size={"xl"} isOpen={visible} toggle={close} title="View Invoice" loading={loading} isFooterHide>
      <Form className="row gy-4 form-validation">
        <Col md="12">
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              height: "750px",
              width: "100%",
              overflow: "auto",
            }}
          >
            {error ? (
              <div>Error loading PDF. Please check the URL or try again later.</div>
            ) : (
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <Viewer
                  fileUrl={url}
                  plugins={[defaultLayoutPluginInstance]}
                  onLoadError={(err) => {
                    console.error("Error loading PDF:", err);
                    setError(true);
                  }}
                />
              </Worker>
            )}
          </div>
        </Col>
      </Form>
    </AnnsModal>
  );
};

export default PDFViewModal;
